import React from "react";
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { PHARMACY_ROUTES } from "./path";
import Login from '../Components/Login'
import Home from "../Components/Home";
function OverallRoutes () {
    return (
        <Router>
            <Routes>
                <Route path={PHARMACY_ROUTES.LOGIN} element={<Login />} />
                <Route path={PHARMACY_ROUTES.HOME} element={<Home />} />
            </Routes>
        </Router>
    );
};

export default OverallRoutes;
