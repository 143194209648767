import React from 'react';
import CureBaylogo from '../Assets/CurebayLogo.svg';
import LoginImage from '../Assets/LoginImage.svg'

function Login() {
  return (
    <div className="flex flex-col md:flex-row min-h-screen">
      {/* Left Section */}
      <div className="md:w-2/5 bg-[#F4F6FE]  items-center justify-center p-2 relative">
        <img src={CureBaylogo} className="w-1/2 md:w-[38%] absolute mt-16 md:top-2 left-1/4 md:left-1/4 transform -translate-x-1/2 -translate-y-1/2" alt="CureBay Logo" />
        <img 
          src={LoginImage} 
          className="hidden md:block w-[80%] absolute top-[150px] left-1/2 transform -translate-x-1/2" 
          alt="Login Illustration" 
        />
      </div>
      
      {/* Right Section */}
      <div className="md:w-3/5 flex flex-col justify-center items-center p-6 md:p-24">
        <h1 className="text-2xl md:text-3xl text-bold text-[#004276] mb-4">Welcome to Pharmacy</h1>
        <p className="text-center text-gray-700 mb-10">
          Your health is our priority. Explore our services and connect with healthcare professionals.
        </p>
        <form className="w-full max-w-md flex flex-col items-center">
          <div className="w-full mb-5">
            <label htmlFor="email" className="block text-[#004276] mb-2">Email Address</label>
            <input 
              type="email"
              id="email"
              className="w-full rounded shadow-lg border border-solid border-gray-300 focus:border-blue-500 h-10 px-3 transition duration-200"
            />
          </div>
          <div className="w-full mb-5">
            <label htmlFor="password" className="block text-[#004276] mb-2">Password</label>
            <input 
              type="password"
              id="password"
              className="w-full rounded shadow-lg border border-solid border-gray-300 focus:border-blue-500 h-10 px-3 transition duration-200"
            />
            <a href="/forgot-password" className="block text-right mt-1 text-blue-500 hover:underline text-xs">Forgot Password?</a>
          </div>
          <button 
            type="submit"
            className="w-[80%] rounded-full shadow-xl border border-solid border-gray-300 bg-[#004276] text-white h-10 transition duration-200 hover:bg-[#00355c]"
          >
            Login
          </button>
        </form>
      </div>
    </div>
  );
}

export default Login;
